export default{
    pdf__viewer__bg: {
        //backgroundColor: 'rgba(0,0,0,0.7)',
        backgroundColor: '#ebebeb',
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: '9996',
      },
    
      pdf__viewer__view : {
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: '9997',
        '.react-pdf__message.react-pdf__message--error' : {
          padding : '0.5rem',
          backgroundColor : 'red',
          color: 'white',
          fontWeight : 700
        },
        '.react-pdf__Page__annotations.annotationLayer' : {
          display: 'none'
        }
      },
    
      pdf__viewer__view__bar : {
        backgroundColor : 'black',
        padding : '1rem',
        color : 'white',
        fontWeight : 700,
        position : 'relative',
        '.pdf__viewer__view__bar__pdf__download' : {
          position : 'absolute',
          right: '5px',
          top : '50%',
          transform : 'translateY(-50%)',
        }
      },
    
      pdf__viewer__view__contetns : {
        width : '100%',
        height : '100%',
        position : 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingBottom : '3rem'
      },
    
      pdf__viewer__view__contetns__des : {
    
      },
    
      pdf__viewer__view__page : {
        paddingTop : '1.5rem',
        backgroundColor : '#ebebeb',
        position : 'relative',
        overflowX : 'auto',
        overflowY : 'hidden'
      },
      pdf__viewer__view__index : {
        position : 'absolute',
        top : '3px',
        left: '10px',
        fontSize : '0.8rem'
      }
}
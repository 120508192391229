//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../../cssInJs'

export default {
  pageTiles: css(mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden',

    '.pageTile__bsyc__tile': {
      flexGrow: '1',
      flexShrink: '1',
      padding: '10px',

      //for type1
      flexBasis: ['100%', null, '50%', '33.3%'],
      maxWidth: ['100%', null, '50%', '33.3%'],
    },

    // '&.useSlider .pageTile__bsyc__tile': {

    // }

  })),

  slider: css({
    '.slick-prev': css(mq({
      left: ['0px !important', null, '-20px !important'],
    })),
    '.slick-next': css(mq({
      right: ['0px !important', null, '-20px !important']
    })),
    '.slick-next:before, .slick-prev:before': css({
      color: 'black'
    }),
    '.tile__details__container': {
      height: '100%'
    },

    '.pageTile__bsyc__tile': {
      //flexBasis: '100% !important',
      //maxWidth: '100% !important',
      height: '100% !important',
    }


  })

}
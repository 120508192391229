/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import style from './tileStyle';
import { SiteLink } from '../../../components';

export function Tile(props) {

    const page = props.page;
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    const disableLink = props.disableLink;
    const colorTheme = page.colorTheme;

    return (
        <div css={style.pageTile__tile} className="pageTile__bsyc__tile">
            <SiteLink className="pageTile__bsyc__content" css={style.tile__content} to={disableLink ? '' : page.pageUrl}>
                <div css={style.tile__flip__wrapper} className="tile__flip__wrapper" data-aos='fade-up-right' data-aos-offset="50" data-aos-anchor-placement="top-center">

                    <div className="pageTile__bsyc__bg" css={style.tile__bg}>
                        <div className="pageTile__bsyc__bg2" css={style.tile__bg2} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
                    </div>

                    <div css={style.tile__details} className={`pageTile__bsyc__content__details ${colorTheme}`} >
                        <div className="pageTile__bsyc__container">
                            {
                                (page.pageTitle && page.pageTitle !== '') &&
                                <div className="pageTile__bsyc__title">
                                    {page.pageTitle}
                                </div>
                            }
                            <div className="pageTile__bsyc__desc">
                                {page.tileDesc || page.description}
                            </div>

                            <div className="pageTile__bsyc__learn__more">
                                <div className="pageTile__bsyc__learn__more__text">
                                    LEARN MORE
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SiteLink>
        </div>
    )
}
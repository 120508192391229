//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../../cssInJs'

export default {
    pageTiles: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden',
        '.pageTile__bsyc__tile': {
            flex: '0 0 100%',
            maxWidth: '100%',
            padding: '10px',
            '&:nth-of-type(even)': {
                '.pageTile__bsyc__bg': mq({
                    //order: ['0', null, '2'],
                }),
                '.pageTile__bsyc__content__details': mq({
                    //order: '1'
                })
            }
        }
    })
}
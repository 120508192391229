/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useRef, useState, useEffect, Fragment } from 'react';
import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import { createPortal } from "react-dom";
import { useRouter } from '../../../../components';
import { handleElementLink } from '../../../../components';
import env from '../../../../env';
import { renderHtml } from './renderHtml';
import style from './htmlStyle';

//import { Document, Page } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/entry.webpack';

function Portal({ children }) {
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();

  const [pdfViewPath, setPdfViewPath] = React.useState();
  const [pdfViewNumPages, setPdfViewNumPages] = React.useState();
  const [pdfViewWidth, setPdfViewWidth] = React.useState(window.innerWidth);
  const [pdfViewScale, setPdfViewScale] = React.useState(false);

  //const [ pdfViewPath, setPdfViewPath ] = React.useState(null)
  //console.log('item', item, cmsOption);

  const year = (new Date()).getFullYear();
  let content = cmsOption.isCmsEdit ?
    cmsUtils.payload(item, 'Content') :
    cmsUtils.payload(item, 'Content')
      .replace(/__year__/g, year)
      .replace("__version__", "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>");


  const cssClass = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) {

    let target = "";
    if (e.target.closest('a')) {
      target = e.target.closest('a').getAttribute('target');
    }
    const processed = (target === "_blank" ? handleElementLink(e.target, null) : handleElementLink(e.target));
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url);
    }
    else {
      //e.preventDefault();
      //e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))

      // process expandable old
      // if(e.target.closest('a')){
      //   let href = e.target.closest('a').getAttribute('href') + "";
      //     if(href.indexOf('#') !== -1){
      //     let _href = href.split('#');
      //     console.log("_href", _href);
      //     let el = document.querySelector('section[data-cms-item-anchor="' + _href[1] + '"]');
      //     if (el) {
      //       let anchorStatus = el.getAttribute('data-anchor-status');
      //       if(anchorStatus === "collapse"){
      //         el.setAttribute('data-anchor-status', 'expand');
      //         el.classList.add("expand");
      //       }
      //       else{
      //         el.setAttribute('data-anchor-status', 'collapse');
      //         el.classList.remove("expand");
      //       }
      //     }
      //     e.preventDefault();
      //     e.stopPropagation(); 
      //   }
      // }

      // process expandable
      if (e.target.closest('a')) {
        let aLink = e.target.closest('a')
        let href = aLink.getAttribute('href') + "";
        if (href.indexOf('#') !== -1) {
          let _href = href.split('#');
          console.log("_href", _href);
          const targetId = _href[1]
          let targetElt = document.querySelector('section[data-cms-item-anchor="' + _href[1] + '"]');

          const runEffect = () => {
            let anchorStatus = targetElt.getAttribute('data-anchor-status');
            if (anchorStatus === "collapse") {
              targetElt.setAttribute('data-anchor-status', 'expand');
              targetElt.classList.add("expand");
            }
            else {
              targetElt.setAttribute('data-anchor-status', 'collapse');
              targetElt.classList.remove("expand");
            }
          }

          if (targetElt) {
            const wrapper = aLink.closest('p, div, h1, h2, h3, h4, h5')
            const nextElt = wrapper.nextElementSibling
            //console.log(wrapper, nextElt)

            if (nextElt && nextElt.getAttribute('data-cms-item-anchor') === targetId) {
              // already in wrapper
            } else {
              // no wrapper
              wrapper.insertAdjacentElement("afterend", targetElt);
            }

            window.setTimeout(runEffect, 100)

            e.preventDefault();
            e.stopPropagation();
          }
        }

        if (window.innerWidth <= 900 && href.includes('.pdf')) {
          e.preventDefault();
          e.stopPropagation();
          setPdfViewPath(href);
          setPdfViewScale(false);
          document.body.style.overflow = "hidden";
        }

      }
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    const result = [];
    for (let i = 0; i < numPages; i++) {
      result.push(i);
    }
    setPdfViewNumPages(result);
  }

  function closePdfViewer() {
    setPdfViewPath(null);
    setPdfViewNumPages(null);
    document.body.style.overflow = "unset";
  }

  function changePdfViewScale() {
    setPdfViewScale(!pdfViewScale);
  }

  const htmlBlock = useRef(null);
  
  useEffect(() => {

    const portrait = window.matchMedia("(orientation: portrait)");
    portrait.addEventListener("change", function (e) {
      if (e.matches) {
        // Portrait mode
        //console.log("111");
      } else {
        // Landscape
        //console.log("222");
      }
      setPdfViewWidth(window.innerWidth);
    });

  }, []);
  
  return (
    <Fragment>
      <div ref={htmlBlock} className={cssClass} data-cms-html="true"
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        onClick={(e) => handleClick(e)}>
        {renderHtml(content, cmsOption)}
      </div>
      {
        pdfViewPath &&
        <Portal>
          <PdfViewItem
            pdfViewPath={pdfViewPath}
            pdfViewNumPages={pdfViewNumPages}
            pdfViewWidth={pdfViewWidth}
            pdfViewScale={pdfViewScale}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            closePdfViewer={closePdfViewer}
            changePdfViewScale={changePdfViewScale} />
        </Portal>
      }
    </Fragment>
  )
}

function PdfViewItem({ pdfViewPath, pdfViewNumPages, pdfViewWidth, pdfViewScale, onDocumentLoadSuccess, closePdfViewer, changePdfViewScale }) {

  const abc = (event) => {
    if (event.scale !== 1) { event.preventDefault(); }
  };

  useEffect(() => {
    document.addEventListener('touchmove', abc, { passive: false });
    return () => {
      document.removeEventListener('touchmove', abc, { passive: false });
    }
  }, []);

  return (
    <Fragment>
      <div className="pdf__viewer__bg" css={style.pdf__viewer__bg}></div>
      <div className="pdf__viewer__view" css={style.pdf__viewer__view}>

        <div className="pdf__viewer__view__bar" css={style.pdf__viewer__view__bar}>
          <div onClick={closePdfViewer} style={{ display: 'inline-block' }}>CLOSE</div>
          {
            pdfViewNumPages &&
            <a className='pdf__viewer__view__bar__pdf__download' href={pdfViewPath} download> DOWNLOAD </a>
          }

          {
            pdfViewNumPages &&
            <div onClick={changePdfViewScale} style={{ position: 'absolute', fontSize: '22px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} > - / + </div>
          }

        </div>
        <div className="pdf__viewer__view__contetns" css={style.pdf__viewer__view__contetns}>
          <div className='pdf__viewer__view__contetns__des' css={style.pdf__viewer__view__contetns__des}>
        
            <Document file={pdfViewPath} onLoadSuccess={onDocumentLoadSuccess}>

              {
                pdfViewNumPages &&
                pdfViewNumPages.map((item, index) => {
                  return (
                    <Page key={index} pageIndex={index} scale={pdfViewScale === false ? 1.0 : 2.0} width={pdfViewWidth} className='pdf__viewer__view__page' css={style.pdf__viewer__view__page}>
                      <div className='pdf__viewer__view__index' css={style.pdf__viewer__view__index}>{(index + 1) + ' of ' + pdfViewNumPages.length}</div>
                    </Page>
                  )
                })
              }

            </Document>

          </div>

        </div>
      </div>
    </Fragment>
  )
}
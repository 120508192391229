import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

const numberOfTiles = [1, 2, 3, 3]
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const widthPercent = (index) => 100 / numberOfTiles[index] + '%'

const linkText = {
  color: variables.primaryColor,
  fontSize: '0.8rem',
  padding: '0 20px',
  // '&:hover': {
  //   color: variables.fontColor,
  // }
}

export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '0.6rem',
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //maxWidth:['800px'],
    margin: 'auto'
  }),
  categoryList : mq({
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth:['800px'],
    margin: 'auto',
    'div.ff.form-group' : {
      maxWidth: '250px'
    }
  })
}

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingLeft : '10px',
    //paddingRight : '10px',
    //paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    //flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), "33.33%"],
    flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), "32.33%"],
    flexGrow: 0,
    flexShrink: 0,
    borderRadius : '20px',
    boxShadow: '1px 2px 10px rgb(190 190 190)',
    overflow: 'hidden',
  }),
  tileLink: {
    //background: variables.primaryColor,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    //padding: 5,
    //border: '1px solid red',
    '&:hover': {
      textDecoration: 'none'
      //background: '#f3f3f3'
    }
  },
  prdImage: {
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    width: '100%',
    backgroundColor: '#f5f5f5'
  },
  bg: {
    //height: 0,
    //paddingBottom: '100%',
    //paddingTop: '210px',
    paddingTop: '341px',
    paddingBottom: '20px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  shortDes : css(linkText, {
    fontSize: '0.8rem',
    textAlign:'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    //minHeight: '65px',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
    //marginTop: '0.8rem'
  }),
  name: css(linkText, {
    fontWeight: '700',
    fontFamily: variables.familyHeader,
    fontSize: '1.4rem',
    textAlign:'left',
    marginTop:'8px',
    marginBottom: '4px',
    textTransform: 'capitalize'
  }),
  price: css(linkText, {
    fontWeight: 'bold',
    marginTop: 'auto',
    textAlign:'left',
    marginBlock:'.8rem'
  }),
  buyNow: css({
    display: 'display-inline',
    width: '150px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginLeft: '20px',
    marginBottom: '1rem',
    padding: '4px 12px',
    color: '#16b7ef',
    border: '2px solid #16b7ef',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#16b7ef',
      color: '#fff'
    }
  })
}
import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {

    pageTile__tile: css(mq({
        // flex: '0 0 100%',
        // maxWidth: '100%',
        padding: '10px',
        //height: '100%'
    })),

    tile__content: css({
        '&:hover': {
            textDecoration: 'none',
            '& .tile__flip__wrapper' : {
                backgroundColor: 'white'
            },
            '& .pageTile__bsyc__content__details': {
                
                // '&:after' : {
                //     backgroundColor: '#DD3430'
                // },
                // '&:before' : {
                //     background : 'white'
                // },
                //backgroundColor : 'white',
                //backgroundColor: variables.colorYellow
                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
                '& .pageTile__bsyc__title' : {
                    color : variables.colorBlue
                },
                '& .pageTile__bsyc__desc' : {
                    color : variables.colorBlue
                }
            },
            '& .pageTile__bsyc__bg2': {
                //transform: scale(1.05)
                transform: 'scale(1.05)'
            },
            '& .pageTile__bsyc__learn__more__text' : {
                backgroundColor : 'white !important',
                color : variables.colorLightBlue + ' !important',
                border : '2px solid ' + variables.colorLightBlue + ' !important',
            } 
        }
    }),

    tile__flip__wrapper: css({
        //zIndex: '-1',
        display: 'flex',
        //flexDirection: 'row',
        //justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        //flexWrap: 'wrap',
        backgroundColor: variables.colorBlue,
        borderRadius : '20px',
        overflow : 'hidden',
        //boxShadow : '-1px 1px 5px rgb(190 190 190 / 50%)'
        boxShadow: '1px 2px 10px rgb(190 190 190)',
        flexDirection : 'column'
    }),

    tile__bg: css(mq({
        height: '0',
        paddingTop: ['56.25%'],
        //height: ['50%','35%','30%'],
        //flex: '1 1 300px',
        //flex: ['1 0 100%'],
        //maxWidth : ['100%'],        
        overflow: 'hidden',
        position: 'relative',
        order: ['0']
    })),

    tile__bg2: css(mq({
        height: '100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //overflow:'hidden',
        transition: 'all .2s',
        position: 'absolute',
        top: '0px',
        width: '100%'
    })),

    tile__details: css(mq({
        overflow: 'hidden',
        flexGrow: '1',
        //textAlign: 'center',
        width: '100%',
        bottom: '0',
        //backgroundColor: variables.colorCream,
        
        //flex: '1 1 300px',
        //flex: ['1 0 100%'],
        //maxWidth : ['100%'],
        
        transition: 'all .2s ease-in-out',
        position: 'relative',

        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static'],
            //border: '1px solid red',
            width : ['100%'],
            //height: '100%',
            top : '50%',
            left : '50%',
            //transform : ['translate(0, 0)'],
            // top: '50%',
            // left: '50%',
            // transform: ['none', null, 'translate(-50%, -50%)'],
            //width: ['100%', null, '90%'],
            //padding: ['1rem', null, '0px'],
            padding : ['2rem',null,'1rem'],
            paddingTop : ['2rem',null,'1rem'],
            zIndex: 1,
            //backgroundColor: '#F9F8F8',
            '& .pageTile__bsyc__title': mq({
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: ['1.6rem',null,'1.2rem'],
                //textTransform: 'uppercase',
                fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                //fontFamily: variables.familyHeader,
                //paddingTop: '.5rem',
                //paddingBottom: '.5rem',
                color: 'white',
                //maxWidth: '410px'
            }),
            '& .pageTile__bsyc__desc': mq({
                lineHeight: '1.3',
                //fontSize: '.9rem',
                //color: '#3d3d3d',
                //minHeight: '70px',
                color: 'white',
                marginTop: ['1rem',null,'1rem'],
                fontSize : ['1rem',null,'0.8rem'],
            }),
            '& .pageTile__bsyc__learn__more' : mq({
                marginTop: ['1rem',null,'1rem'],
                '.pageTile__bsyc__learn__more__text' : {
                    color: 'white',
                    backgroundColor : variables.colorLightBlue,
                    display : 'inline-block',
                    border : '2px solid ' + variables.colorLightBlue,
                    //border : '2px solid black',
                    fontWeight : '700',
                    padding : '0.1rem 1rem',
                    borderRadius: '30px',
                    fontSize : ['1rem',null,'0.8rem']
                }
            })
        })
    }))
}